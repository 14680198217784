import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import App from './App';
import { logPageView } from './utils/analytics';
// import './index.css';

const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return null;
};

const AppWithRouter = () => (
  <Router>
    <Analytics />
    <App />
  </Router>
);

ReactDOM.render(<AppWithRouter />, document.getElementById('root'));

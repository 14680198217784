import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Perguntas frequentes
      </Typography>
      <Box sx={{ width: '100%' }}>

      <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography component="h3" variant="subtitle2">
            Qual o critério de escolha das famílias?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              O grupo de voluntários do projeto Reconstrói se estabeleceu durante 30 dias (mês de maio) no Abrigo Caixeiros Viajantes e auxiliou fortemente no Abrigo Instituto Colo de Mãe. Então, primeiramente, as doações serão entregues para as famílias que estavam nesses abrigos, com o objetivo de cuidarmos delas da chegada no abrigo até a reconstrução e reestabelecimento de suas moradias. Depois de atendermos as necessidades básicas deles, seguiremos ampliando o alcance do projeto.
            </Typography>
          </AccordionDetails>
        </Accordion>    

        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography component="h3" variant="subtitle2">
            Quanto cada familia irá receber?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              Nenhuma familia receberá dinheiro físico ou PIX, mas sim os itens que necessitam para seguirem suas vidas em segurança. Isso quer dizer que cada família terá acesso única e exclusivamente aquilo que precisa.
            </Typography>
          </AccordionDetails>
        </Accordion>    


        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography component="h3" variant="subtitle2">
            Por que devo confiar no Reconstrói?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
            Este projeto é feito por um grupo de voluntários que estão trabalhando desde o inicio da catástrofe que assolou o estado do RS no Abrigo Caixeiros Viajantes. Cada pessoa tem o seu trabalho, mas que entenderam a necessidade do momento e focaram em ajudar, acolher e de alguma forma dar amor para essas pessoas. O Reconstrói existe desde o inicio de maio e já arrecadou e destinou mais de R$40.000,00. 
            </Typography>
          </AccordionDetails>
        </Accordion>    


        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography component="h3" variant="subtitle2">
            Quais as formas que posso contribuir?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
            A forma que você pode contribuir é aqui, através do nosso site, escolhendo uma ou mais doações ou também através do PIX <a href="mailto:reconstroi.rs24@gmail.com">reconstroi.rs24@gmail.com</a>, email do projeto, em nome de Tais Barreto Nunes (Banco Nubank). Através do nosso Instagram <a href="https://www.instagram.com/reconstroi_rs/">@reconstroi_rs</a>, divulgamos um qr-code com o PIX, que também direciona para essa mesma conta bancária.
            </Typography>
          </AccordionDetails>
        </Accordion>    

       <Accordion
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5d-content"
            id="panel5d-header"
          >
            <Typography component="h3" variant="subtitle2">
            Vocês irão reconstruir a casa das pessoas afetadas?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
            Não faremos isso. Nosso objetivo é captar recursos financeiros e doações, tanto de pessoas físicas quanto jurídicas, para que as próprias famílias reconstruam seus espaços. Nosso foco maior está em fornecer eletrodomésticos, móveis como armários e guarda roupas, cama e colchão, cobertores, kits de higiene, kit de limpeza, cestas básicas e tudo aquilo que possa ajudar a trazer minimamente o conforto e segurança dessas famílias afetadas.
            </Typography>
          </AccordionDetails>
        </Accordion>    

        <Accordion
          expanded={expanded === 'panel6'}
          onChange={handleChange('panel6')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6d-content"
            id="panel6d-header"
          >
            <Typography component="h3" variant="subtitle2">
            Este projeto tem financiamento do Governo?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
            Não. Este projeto é realizado, única e exclusivamente, por pessoas físicas que se uniram e criaram tudo voluntariamente.
            </Typography>
          </AccordionDetails>
        </Accordion>  


        <Accordion
          expanded={expanded === 'panel7'}
          onChange={handleChange('panel7')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7d-content"
            id="panel7d-header"
          >
            <Typography component="h3" variant="subtitle2">
            Como funciona o processo de doação?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              <ul>
                  <li>Passo 1: Selecione o pacote de doação.</li>
                  <li>Passo 2: Preencha o formulário com as informações do doador, atendendo-se para informar um CPF ou CNPJ válido.</li>
                  <li>Passo 3: Aguarde a geração do qr-code Pix.</li>
                  <li>Passo 4: Se estiver no computador, pague através da leitura do QR-Code Pix. Se estiver no celular, cópie o código através do botão copiar para utilizar em seu banco.</li> 
              </ul>
              Importante: sua doação só será efetivada após o pagamento do QR-Code Pix através do seu Banco. O QR-Code é válido por 5 dias.
            </Typography>
          </AccordionDetails>
        </Accordion>    

        <Accordion
          expanded={expanded === 'panel0'}
          onChange={handleChange('panel0')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel0d-content"
            id="panel0d-header"
          >
            <Typography component="h3" variant="subtitle2">
            Como posso entrar em contato com os responsáveis pela iniciativa?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              Para entrar em contato com o grupo de voluntários desta iniciativa, utilize o e-mail <a href="mailto:reconstroi.rs24@gmail.com">reconstroi.rs24@gmail.com</a> ou o nosso Instagram <a href="https://www.instagram.com/reconstroi_rs/">@reconstroi_rs</a>.
            </Typography>
          </AccordionDetails>
        </Accordion>        


      </Box>
    </Container>
  );
}

import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TeamInfoCard from './TeamInfoCard';

const TeamInfoCards = () => {
  const [loading, setLoading] = useState(true);
  const [team] = useState([
    {
      name: 'Tais Nunes',
      avatar: './images/team/tais.jpeg',
      instagram: 'https://instagram.com/bilhetesderh',
      linkedin: 'https://www.linkedin.com/in/ta%C3%ADsnunes/',
    },
    {
      name: 'Diego Patriota',
      avatar: './images/team/patriota.jpg',
      instagram: 'https://instagram.com/diego_patriota',
      linkedin: 'https://br.linkedin.com/in/diego-patriota-10a148b9',
    },
    {
      name: 'Henrique Nadal',
      avatar: './images/team/nadal.jpg',
      instagram: 'https://instagram.com/ikealmeida',
      linkedin: 'https://br.linkedin.com/in/henriquenadal',
    },
    {
      name: 'Flávio Fagundes',
      avatar: './images/team/flavio.jpg',
      instagram: 'https://instagram.com/flaviozfagundes',
      linkedin: 'https://br.linkedin.com/in/flaviofagundes',
    },
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); 

    return () => clearTimeout(timer); 
  }, []);

  return (
    <Container
      id="teamInfo"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Card
        sx={{
          bgcolor: '#a4bcdd59',
          padding: 4,
          borderRadius: 0,
          textAlign: 'center',
          position: 'absolute',
          top: 0,
          zIndex: 0,
          width: "100%",
          height: 300,
          '@media (max-width: 600px)': {
            md: 230
          },
        }}
      >
        <CardContent>
          <Typography variant="h4" component="div">
           Quem somos
          </Typography>
          <Typography variant="body1" component="div">
            Pessoas unidas em prol de uma causa maior
          </Typography>
        </CardContent>
      </Card>
      
        <Grid
            container
            spacing={2}
            sx={{
                mt: 6,
                '@media (max-width: 600px)': {
                    mt: 15
                }
            }}
        >
        {loading ? (
          Array.from(new Array(4)).map((_, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <TeamInfoCard loading={true} />
            </Grid>
          ))
        ) : (
          team.map((member, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <TeamInfoCard
                name={member.name}
                avatar={member.avatar}
                instagram={member.instagram}
                linkedin={member.linkedin}
                loading={false}
              />
            </Grid>
          ))
        )}
      </Grid>
    </Container>
  );
};

export default TeamInfoCards;

import React from 'react';

const FormStepThree = ({ donationId, qrCode, pixEmv, setStep }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(pixEmv);
    alert('Código copiado para a área de transferência!');
  };

  const goToIndex = () => {
    window.location.href = '/'; // Redireciona para a página inicial
  };

  return (
    <div className="form-step" style={{ textAlign: 'center' }}>
      <h2>Doação #{donationId} registrada com Sucesso!</h2>
      <div>
        <img src={`data:image/png;base64,${qrCode}`} alt="QR Code" style={{ width: '250px', height: '250px' }} />
        <p>
          Faça a leitura do QR Code Pix ou copie o código para efetuar o pagamento em seu banco e concluir a doação.
          <button className="copy-button" onClick={copyToClipboard}>Copiar Código</button>
        </p>
      </div>
      <div style={{ marginTop: '20px' }}>
        <button onClick={goToIndex} style={{ padding: '10px 20px', cursor: 'pointer' }}>Voltar ao Início</button>
      </div>
    </div>
  );
};

export default FormStepThree;
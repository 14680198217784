import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import CopyToClipboardButton from './CopyToClipboardButton';

export default function AboutDonations() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/packages?neighborhoodId=1&orderBy=value&orderType=asc`)
      .then(response => {
        setProducts(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error("There was an error fetching the products!", error);
        setLoading(false);
      });
  }, []);

  return (
    <Container
      id="aboutDonations"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h6" color="text.primary" sx={{ fontWeight: 'bold' }}>
          Escolha como doar agora
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {loading ? (
          Array.from(new Array(6)).map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  flexGrow: 1,
                  p: 1,
                }}
              >
                <Skeleton variant="rectangular" width="100%" height={140} />
                <CardContent>
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </CardContent>
                <CardActions>
                  <Skeleton variant="rectangular" width={80} height={36} />
                </CardActions>
              </Card>
            </Grid>
          ))
        ) : (
          products.map((product, index) => (
            product.active && (
              <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    flexGrow: 1,
                    p: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      pr: 2,
                    }}
                  >
                    <CardHeader 
                      title={
                        <Typography variant="body1">
                          {product.name}
                        </Typography>
                      }
                    />
                    <CopyToClipboardButton data={`${window.location.toString()}donation-page?id=${product.id}`}/>                    
                  </Box>
                  <CardContent>
                    <Box
                      component="img"
                      src={product.imagePath}
                      alt={product.name}
                      sx={{
                        width: '100%',
                        height: 'auto',
                        borderRadius: '8px',
                        mt: 0, 
                        mb: 2 
                      }}
                    />
                    <Typography 
                      variant="body2" 
                      color="text.secondary" 
                      sx={{
                        mt: 2
                      }}
                    >
                      {product.description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="body1" color="text.secondary">
                        {`R$ ${product.value}`}
                      </Typography>
                    </Box>
                    <Button
                      variant="contained"
                      size="small"
                      component={Link}
                      to={`/donation-page?id=${product.id}`}
                      sx={{
                        backgroundColor: '#388E3C', // Slightly darker green
                        '&:hover': {
                          backgroundColor: '#4caf50', // Green color you provided
                        },
                      }}                      
                    >
                      Doar
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            )
          ))
        )}
      </Grid>
    </Container>
  );
}

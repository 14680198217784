import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import HCaptcha from 'react-hcaptcha';
import { isValidCPFOrCNPJ } from '../utils/documentValidation.js'; // Importar a função de validação

const FormStepTwo = ({
  cities,
  selectedCity,
  neighborhoods,
  selectedNeighborhood,
  selectedPackage,
  donationDetails,
  handleDonationChange,
  handleDonationSubmit,
  setStep
}) => {
  const [captchaValue, setCaptchaValue] = useState(null);
  const [phoneMask, setPhoneMask] = useState('(99) 999999999');
  const [isEditing, setIsEditing] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(process.env.NODE_ENV !== 'production');
  const [documentError, setDocumentError] = useState('');
  const [nameError, setNameError] = useState('');

  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    const unformattedValue = value.replace(/[^\d]/g, '');
  
    if (unformattedValue.length <= 11) {
      setPhoneMask('(99) 999999999');
    }
  
    handleDonationChange({
      target: {
        name,
        value: unformattedValue
      }
    });
  };
  
  const handlePhoneBlur = (e) => {
    const { value } = e.target;
    const unformattedValue = value.replace(/[^\d]/g, '');
  
    const currentFormat = unformattedValue.length === 11 ? '(99) 99999-9999' : '(99) 9999-9999';
  
    if (currentFormat !== phoneMask) {
      setPhoneMask(currentFormat);
    }
  
    setIsEditing(false);
  };
  
  const handlePhoneFocus = () => {
    setIsEditing(true);
    setPhoneMask('(99) 999999999');
  };

  const handleDocumentChange = (e) => {
    const { name, value } = e.target;
    const unformattedValue = value.replace(/[^\d]/g, '');

    handleDonationChange({
      target: {
        name,
        value: unformattedValue
      }
    });

    if (isValidCPFOrCNPJ(unformattedValue)) {
      setDocumentError('');
    } else {
      setDocumentError('Documento inválido. Por favor, insira um CPF ou CNPJ válido.');
    }
  };

  const handleNameChange = (e) => {
    const { name, value } = e.target;
    handleDonationChange(e);

    const nameParts = value.trim().split(' ');
    if (nameParts.length < 2) {
      setNameError('Por favor, informe pelo menos um nome e um sobrenome.');
    } else {
      setNameError('');
    }
  };

  const onCaptchaChange = (value) => {
    setCaptchaValue(value);
    setIsCaptchaVerified(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (process.env.NODE_ENV === 'production' && !captchaValue) {
      alert("Por favor, complete o CAPTCHA");
      return;
    }
    if (documentError) {
      alert("Por favor, corrija os erros no formulário antes de enviar.");
      return;
    }
    if (nameError) {
      alert("Por favor, corrija os erros no formulário antes de enviar.");
      return;
    }
    handleDonationSubmit(e, captchaValue);
  };

  return (
    <div className="form-step">
      <h3 className="form-step-title">Informações sobre a Doação</h3>

      <div className="form-group">
        <div>
          <span>Pacote</span>
          <span><strong>{selectedPackage.name}</strong> - {selectedPackage.description}</span>
        </div>
        <div>
          <span>Valor</span>
          <span><strong>R${selectedPackage.value}</strong> </span>
        </div>
      </div>
      <hr />
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <h3 className="form-step-title">Informações do Doador</h3>
          <label htmlFor="donatorName">Nome</label>
          <input
            type="text"
            id="donatorName"
            name="donatorName"
            value={donationDetails.donatorName}
            onChange={handleNameChange}
            required
          />
          {nameError && <span className="error-message">{nameError}</span>}
        </div>
        <div className="form-group">
          <label htmlFor="donatorDocument">Documento (CPF/CNPJ)</label>
          <input
            type="text"
            id="donatorDocument"
            name="donatorDocument"
            value={donationDetails.donatorDocument}
            onChange={handleDocumentChange}
            required
          />
          {documentError && <span className="error-message">{documentError}</span>}
        </div>
        <div className="form-group">
          <label htmlFor="donatorEmail">E-mail</label>
          <input
            type="email"
            id="donatorEmail"
            name="donatorEmail"
            value={donationDetails.donatorEmail}
            onChange={handleDonationChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="donatorMobile">Telefone</label>
          <InputMask
            mask={phoneMask}
            maskChar={null}
            value={donationDetails.donatorMobile}
            onChange={handlePhoneChange}
            onBlur={handlePhoneBlur}
            onFocus={handlePhoneFocus}
          >
            {(inputProps) => <input {...inputProps} type="text" id="donatorMobile" name="donatorMobile" required />}
          </InputMask>
        </div>
        {process.env.NODE_ENV === 'production' && (
          <HCaptcha
            sitekey={'68a0a7a0-a0fa-469e-b93a-1ec29e1e75e6'}
            onVerify={onCaptchaChange}
          />
        )}
        <div className="button-group">
          <button
            type="submit"
            disabled={process.env.NODE_ENV === 'production' && !isCaptchaVerified}
            style={{
              backgroundColor: isCaptchaVerified || process.env.NODE_ENV !== 'production' ? 'green' : 'darkgray',
              cursor: isCaptchaVerified || process.env.NODE_ENV !== 'production' ? 'pointer' : 'not-allowed'
            }}
          >
            Enviar Doação
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormStepTwo;
import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Skeleton from '@mui/material/Skeleton';

const TeamInfoCard = ({ name, avatar, instagram, linkedin, loading }) => {
  return (
    <Card sx={{ minWidth: 260, boxShadow: 0, mt: 2, height: 340, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
      <CardContent>
        {loading ? (
          <Skeleton variant="circular" width={230} height={230} />
        ) : (
          <Avatar alt={name} src={avatar} sx={{ width: 230, height: 230, marginBottom: 2 }} />
        )}
        {loading ? (
          <Skeleton variant="text" width={120} height={30} />
        ) : (
          <Typography variant="h6" component="div" sx={{ marginBottom: 2 }}>
            {name}
          </Typography>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
          {loading ? (
            <>
              <Skeleton variant="circular" width={30} height={30} />
              <Skeleton variant="circular" width={30} height={30} />
            </>
          ) : (
            <>
              <Link href={instagram} target="_blank" rel="noopener" sx={{ color: '#E4405F' }}>
                <InstagramIcon />
              </Link>
              <Link href={linkedin} target="_blank" rel="noopener" sx={{ color: '#0077B5' }}>
                <LinkedInIcon />
              </Link>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default TeamInfoCard;

import React from 'react';
import Grid from '@mui/material/Grid';
import CardIndividual from './CardIndividual';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import GroupsIcon from '@mui/icons-material/Groups';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import Skeleton from '@mui/material/Skeleton'; // Importando o Skeleton

const DonationInfoCards = ({ arrecadado, destinado, doadores, totalDoacoes }) => {
  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={12} sm={3}>
        {arrecadado ? (
          <CardIndividual title="Arrecadado" value={arrecadado} isCurrency={true} Icon={MonetizationOnIcon} iconColor="#0f4f37" />
        ) : (
          <Skeleton variant="rectangular" width="100%" height={150} />
        )}
      </Grid>
      <Grid item xs={12} sm={3}>
        {destinado ? (
          <CardIndividual title="Destinado" value={destinado} isCurrency={true} Icon={CurrencyExchangeIcon} iconColor="#fbc404" />
        ) : (
          <Skeleton variant="rectangular" width="100%" height={150} />
        )}
      </Grid>
      <Grid item xs={12} sm={3}>
        {doadores ? (
          <CardIndividual title="Doadores" value={doadores} isCurrency={false} Icon={GroupsIcon} iconColor="#e82d33" />
        ) : (
          <Skeleton variant="rectangular" width="100%" height={150} />
        )}
      </Grid>
      <Grid item xs={12} sm={3}>
        {totalDoacoes ? (
          <CardIndividual title="Total Doações" value={totalDoacoes} isCurrency={false} Icon={FactCheckIcon} iconColor="#1976d2" />
        ) : (
          <Skeleton variant="rectangular" width="100%" height={150} />
        )}
      </Grid>
    </Grid>
  );
};

export default DonationInfoCards;

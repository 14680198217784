import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Box, Container, Paper, Accordion, AccordionSummary, Stack} from '@mui/material';
import FormStepOne from './FormStepOne';
import FormStepTwo from './FormStepTwo';
import FormStepThree from './FormStepThree';
import ClipLoader from 'react-spinners/ClipLoader'; 
import '../css/DonationForm.css';

const DonationForm = () => {
  const [step, setStep] = useState(1);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [selectedNeighborhood, setSelectedNeighborhood] = useState('');
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [donationDetails, setDonationDetails] = useState({
    donatorName: '',
    donatorDocument: '',
    donatorEmail: '',
    donatorMobile: ''
  });
  const [qrCode, setQrCode] = useState('');
  const [pixEmv, setPixEmv] = useState('');
  const [loading, setLoading] = useState(false);
  const [donationId, setDonationId] = useState(null);


  useEffect(() => {
    console.log(`${process.env.REACT_APP_API_URL}/cities`)
    axios.get(`${process.env.REACT_APP_API_URL}/cities`)
      .then(response => setCities(response.data))
      .catch(error => console.error('Erro ao buscar cidades:', error));
  }, []);

  useEffect(() => {
    if (selectedCity) {
      axios.get(`${process.env.REACT_APP_API_URL}/neighborhoods?cityId=${selectedCity}`)
        .then(response => setNeighborhoods(response.data))
        .catch(error => console.error('Erro ao buscar bairros:', error));
    } else {
      setNeighborhoods([]);
      setPackages([]);
    }
  }, [selectedCity]);

  useEffect(() => {
    if (selectedNeighborhood) {
      axios.get(`${process.env.REACT_APP_API_URL}/packages?neighborhoodId=${selectedNeighborhood}&orderBy=value&orderType=asc`)
        .then(response => setPackages(response.data))
        .catch(error => console.error('Erro ao buscar pacotes:', error));
    } else {
      setPackages([]);
    }
  }, [selectedNeighborhood]);

  const handlePackageSelect = (pkgId) => {
    axios.get(`${process.env.REACT_APP_API_URL}/packages?neighborhoodId=${selectedNeighborhood}&orderBy=value&orderType=asc`)
      .then(response => {
        const selected = response.data.find(pkg => pkg.id === parseInt(pkgId));
        setSelectedPackage(selected);
        setStep(2);
      })
      .catch(error => console.error('Erro ao buscar detalhes do pacote:', error));
  };

  const handleDonationChange = (e) => {
    const { name, value } = e.target;
    setDonationDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handleDonationSubmit = async (e, captchaValue) => {
    e.preventDefault();
    setLoading(true);
    const donationData = {
      ...donationDetails,
      packages: [selectedPackage.id],
      totalValue: parseFloat(selectedPackage.value),
      neighborhoodId: selectedNeighborhood,
      captchaValue // Adding captcha value to donation data
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/donations`, donationData);
      const donationId = response.data.id;
      setDonationId(donationId);
      console.log('Doação realizada com sucesso:', response.data);

      // Obter o QR code
      const qrCodeResponse = await axios.post(`${process.env.REACT_APP_API_URL}/donations/${donationId}/qrcode`);
      setQrCode(qrCodeResponse.data.pixQrCode);
      setPixEmv(qrCodeResponse.data.pixEmv);
      setStep(3);
    } catch (error) {
      console.error('Erro ao enviar doação:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Box
          component="img"
          src="/images/banner.webp"
          alt="Banner"
          sx={{
            width: '138%',
            height: '430px',
            borderRadius: '0 0 8px 8px',
            ml: -20,            
            mb: 4,
            mt: -18,
            '@media (max-width: 800px)': {
              width: '104%',
              height: '310px',
              ml: -1,
              mt: -6,
            },
          }}
        />

        <Stack
          spacing={2}
          alignItems="center"
          useFlexGap
          sx={{ width: { xs: '100%', sm: '100%' }, mb: 5 }}
        >
          <Accordion

            sx={{
              boxShadow: 12,
              bgcolor: 'transparent',
              backgroundImage: 'none',
              backgroundColor: 'hsla(220, 60%, 99%, 0.8)',
              mt: -9
            }}
          >
            <AccordionSummary
              aria-label="Expand"
              aria-controls="-content"
              id="-header"
            >
              <Typography
                variant=""
                sx={{
                  boxShadow: 0,
                  bgcolor: 'transparent',
                  backgroundImage: 'none',
                  display: 'block',
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: 'center',
                  fontSize: 'clamp(2rem, 5vw, 1.5rem)',
                  color: 'darkgreen',
                  fontWeight: 'bold'
                }}
              >
                RS&nbsp;
                <Typography
                  component="span"
                  variant="h2"
                  sx={{
                    fontSize: 'inherit',
                    color: 'red',
                    fontWeight: 'bold'
                    //  color: (theme) =>
                    //     theme.palette.mode === 'light' ? 'primary.main' : 'primary.light'
                  }
                  }
                >
                  Reconstrói
                </Typography>
              </Typography>
            </AccordionSummary>
          </Accordion>

          <Typography
            textAlign="center"
            variant="body1"
            color="text.secondary"
            sx={{
              width: { sm: '100%', md: '90%' },
              mt: 4
            }}
          >
            Somos uma plataforma de esperança e solidariedade. Nosso propósito é reunir recursos para a reconstrução e limpeza das casas devastadas pelas enchentes que assolaram o Rio Grande do Sul em maio de 2024. Cada centavo arrecadado será transformado em bens e produtos essenciais que serão destinados às famílias afetadas. Juntos, podemos fazer a diferença e levar conforto, segurança e renovação a quem mais precisa. Venha fazer parte dessa corrente de amor e reconstrução.
          </Typography>

        </Stack>
      <Box mb={4} display="flex" justifyContent="center">
        <Box display="flex" gap={2}>
          <Paper elevation={step >= 1 ? 3 : 1} sx={{ 
              width: 40, 
              height: 40, 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center', 
              borderRadius: '50%',
              backgroundColor: step >= 1 ? '#4caf50' : 'inherit' // Alteração para mudar a cor do bullet
            }}>
            <Typography variant="h6">1</Typography>
          </Paper>
          <Paper elevation={step >= 2 ? 3 : 1} sx={{ 
             width: 40,
             height: 40, 
             display: 'flex', 
             alignItems: 'center', 
             justifyContent: 'center', 
             borderRadius: '50%',
             backgroundColor: step >= 2 ? '#4caf50' : 'inherit' // Alteração para mudar a cor do bullet
             }}>
            <Typography variant="h6">2</Typography>
          </Paper>
          <Paper elevation={step >= 3 ? 3 : 1} sx={{ 
              width: 40,
              height: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              backgroundColor: step >= 3 ? '#4caf50' : 'inherit' // Alteração para mudar a cor do bullet
            }}>
            <Typography variant="h6">3</Typography>
          </Paper>
        </Box>
      </Box>

      {loading ? (
        <div className="spinner-container">
          <ClipLoader size={150} color="#4caf50" />
        </div>
      ) : (
        <>
          {step === 1 && (
            <FormStepOne
              cities={cities}
              selectedCity={selectedCity}
              setSelectedCity={setSelectedCity}
              neighborhoods={neighborhoods}
              selectedNeighborhood={selectedNeighborhood}
              setSelectedNeighborhood={setSelectedNeighborhood}
              packages={packages}
              handlePackageSelect={handlePackageSelect}
            />
          )}

          {step === 2 && selectedPackage && (
            <FormStepTwo
              cities={cities}
              selectedCity={selectedCity}
              neighborhoods={neighborhoods}
              selectedNeighborhood={selectedNeighborhood}
              selectedPackage={selectedPackage}
              donationDetails={donationDetails}
              handleDonationChange={handleDonationChange}
              handleDonationSubmit={handleDonationSubmit}
              setStep={setStep}
            />
          )}

          {step === 3 && (
            <FormStepThree
              donationId={donationId}
              qrCode={qrCode}
              pixEmv={pixEmv}
              setStep={setStep}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default DonationForm;

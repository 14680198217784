import React, { useEffect } from 'react';

const FormStepOne = ({
  cities,
  selectedCity,
  setSelectedCity,
  neighborhoods,
  selectedNeighborhood,
  setSelectedNeighborhood,
  packages,
  handlePackageSelect
}) => {

  // Set default city and neighborhood
  useEffect(() => {
    setSelectedCity(1);
    setSelectedNeighborhood(1);
  }, [setSelectedCity, setSelectedNeighborhood]);

  // Get package id from URL and neighborhood is selected
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const packageId = urlParams.get('id');
    if (packageId && selectedNeighborhood) {
      handlePackageSelect(packageId, selectedNeighborhood);
    }
  }, [handlePackageSelect, selectedNeighborhood]);

  return (
    <div className="form-step">
      <h3 className="form-step-title">Escolha o local</h3>
      <div className="form-group">
        <label>Informe a Cidade</label>
        <select value={selectedCity} disabled={true}>
          {cities.map(city => (
            <option key={city.id} value={city.id}>{city.name}</option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label>Informe o Bairro</label>
        <select
          value={selectedNeighborhood}
          onChange={e => setSelectedNeighborhood(e.target.value)}
          disabled={!selectedCity}
        >
          {neighborhoods.map(neighborhood => (
            <option key={neighborhood.id} value={neighborhood.id}>{neighborhood.name}</option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label>Defina o seu Pacote</label>
        <select
          onChange={e => handlePackageSelect(e.target.value, selectedNeighborhood)}
          disabled={!selectedNeighborhood}
        >
          {packages.map(pkg => (
            <option key={pkg.id} value={pkg.id}>{`${pkg.name} - R$${pkg.value}`}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default FormStepOne;

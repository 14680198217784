import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import DonationInfoCard from './DonationInfoCard';
import axios from 'axios';

export default function Home() {

   const [statistics, setStatistics] = useState([]);
   const [loading, setLoading] = useState(true);
   
  useEffect(() => {
     axios.get(`${process.env.REACT_APP_API_URL}/donations/statistics`)
       .then(response => {
         setStatistics(response.data);
         setLoading(false);
       })
       .catch(error => {
         console.error("There was an error fetching the statistics!", error);
         setLoading(false);
       });
   }, []);

  return (
    <Box
      id="home"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)'
            : 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Box
          component="img"
          src="/images/banner.webp"
          alt="Banner"
          sx={{
            width: '102%',
            height: '430px',
            borderRadius: '0 0 8px 8px',
            mb: 4,
            mt: -40,
            '@media (max-width: 800px)': {
              height: '310px',
              mt: -20,
            },
          }}
        />

        <Stack
          spacing={2}
          alignItems="center"
          useFlexGap
          sx={{ width: { xs: '100%', sm: '98%' } }}
        >
          <Accordion

            sx={{
              boxShadow: 12,
              bgcolor: 'transparent',
              backgroundImage: 'none',
              backgroundColor: 'hsla(220, 60%, 99%, 0.8)',
              mt: -9
            }}
          >
            <AccordionSummary
              aria-label="Expand"
              aria-controls="-content"
              id="-header"
            >
              <Typography
                variant=""
                sx={{
                  boxShadow: 0,
                  bgcolor: 'transparent',
                  backgroundImage: 'none',
                  display: 'block',
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: 'center',
                  fontSize: 'clamp(2rem, 5vw, 1.5rem)',
                  color: 'darkgreen',
                  fontWeight: 'bold'
                }}
              >
                RS&nbsp;
                <Typography
                  component="span"
                  variant="h2"
                  sx={{
                    fontSize: 'inherit',
                    color: 'red',
                    fontWeight: 'bold'
                    //  color: (theme) =>
                    //     theme.palette.mode === 'light' ? 'primary.main' : 'primary.light'
                  }
                  }
                >
                  Reconstrói
                </Typography>
              </Typography>
            </AccordionSummary>
          </Accordion>

          <Typography
            textAlign="center"
            variant="body1"
            color="text.secondary"
            sx={{
              width: { sm: '100%', md: '90%' },
              mt: 4
            }}
          >
            Somos uma plataforma de esperança e solidariedade. Nosso propósito é reunir recursos para a reconstrução e limpeza das casas devastadas pelas enchentes que assolaram o Rio Grande do Sul em maio de 2024. Cada centavo arrecadado será transformado em bens e produtos essenciais que serão destinados às famílias afetadas. Juntos, podemos fazer a diferença e levar conforto, segurança e renovação a quem mais precisa. Venha fazer parte dessa corrente de amor e reconstrução.
          </Typography>

          <DonationInfoCard arrecadado={statistics.totalReceived} destinado={statistics.totalDelivered} doadores={statistics.totalDonators} totalDoacoes={statistics.totalDonations} />           
        </Stack>

      </Container>
    </Box>
  );
}

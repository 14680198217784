// CardIndividual.js
import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const formatCurrency = (value) => {
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
};

const CardIndividual = ({ title, value, isCurrency, Icon, iconColor }) => {
  return (
    <Card sx={{ minWidth: 240, boxShadow: 3, mt: 2 }}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
          <Typography variant="h6" component="div">
            {title}
          </Typography>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 40,
            height: 40,
            borderRadius: '50%',
            bgcolor: iconColor,
          }}>
            <Icon sx={{ color: 'white' }} />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h5" color="text.primary">
            {isCurrency ? formatCurrency(value) : value}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardIndividual;
